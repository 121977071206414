<template>
  <div class="page">
    <div>
      <h2>Add new User</h2>
      <form action="" class="add-user-form">
        <el-input placeholder="Email" v-model="addUserForm.email" />
        <el-input placeholder="Password" v-model="addUserForm.password" />
        <el-input placeholder="Firstname" v-model="addUserForm.firstname" />
        <el-input placeholder="Lastname" v-model="addUserForm.lastname" />
        <el-select placeholder="Account Type" v-model="addUserForm.accountType">
          <el-option label="Kunde" value="ACCOUNT_TYPE_CUSTOMER" />
          <el-option label="Werkstatt" value="ACCOUNT_TYPE_GARAGE" />
        </el-select>
        <el-button :disabled="createInProgress" @click="createUser" type="primary">{{ createInProgress ? "creating ..." : "Create Account" }}</el-button>
      </form>
    </div>
    <h2>Registered Users</h2>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Kunden" name="customer">
        <div class="search"><el-input placeholder="Suche ..." v-model="search"></el-input></div>
        <UserTable :type="typeCustomer" @loadmore="loadmore" :items="customerItems" :loading="loading" />
      </el-tab-pane>
      <el-tab-pane label="Werkstätten" name="garage">
        <div class="search"><el-input placeholder="Suche ..." v-model="search"></el-input></div>
        <UserTable :type="typeGarage" @loadmore="loadmore" :items="garageItems" :loading="loading" />
      </el-tab-pane>
      <el-tab-pane label="Eingeladen" name="invited">
        <div class="search"><el-input placeholder="Suche ..." v-model="search"></el-input></div>
        <UserTable type="invited" @loadmore="loadmore" :items="inviteItems" :loading="loading" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserTable from '@/components/UserTable'
import { FETCH_USERS, LOAD_MORE_USERS } from '@/vuex/modules/users/actions';
import constants from "@/constants";
import apiService from "../services/api";
let searchTimeout = null;

export default {
  name: 'Users',
  data() {
    return {
      search: '',
      activeTab: 'customer',
      typeCustomer: constants.accountType.customer,
      typeGarage: constants.accountType.garage,

      addUserForm: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        accountType: 'ACCOUNT_TYPE_GARAGE'
      },
      createInProgress: false,
    }
  },
  async created() {
      this.updateUsers();
  },
  computed: {
    ...mapState({
      env: state => state.settings.env,
      items: state => state.users.items,
      accountType: state => state.users.accountType,
      loading: state => state.users.loading,
    }),

    customerItems() {
      if (this.accountType !== constants.accountType.customer) {
        return [];
      }
      return this.items;
    },

    garageItems() {
      if (this.accountType !== constants.accountType.garage) {
        return [];
      }
      return this.items;
    },

    inviteItems() {
      if (this.accountType !== 'invited') {
        return [];
      }
      return this.items;
    }
  },
  watch: {
    activeTab() {
      this.updateUsers()
    },
    env() {
      this.updateUsers()
    },
    search() {
      if (!searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(() => {
        searchTimeout = null;
        this.updateUsers();
      }, 800);
    }
  },
  methods: {
    updateUsers() {
      let accountType = null;

      if (this.activeTab === "customer") { accountType = constants.accountType.customer; }
      else if (this.activeTab === "garage") { accountType = constants.accountType.garage; }
      else if (this.activeTab === "invited") { accountType = "invited"; }

      this.$store.dispatch(FETCH_USERS, {
        search: this.search,
        accountType
      })
    },
    async createUser() {
      try {
        this.createInProgress = true;
        const result = await apiService.createUser(this.addUserForm);
        this.$message.success(`User ${result.email} created`);
        this.updateUsers();
      } catch (error) {
        this.$message.error(error.message + " -- Check E-Mail and Password are valid");
      } finally {
        this.createInProgress = false;
      }
    },
    loadmore() {
      this.$store.dispatch(LOAD_MORE_USERS);
    }
  },
  components: {
    UserTable
  }
}
</script>

<style lang="scss" scoped>
  .search {
    margin: 20px;
  }

  .add-user-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    max-width: 500px;
    margin-bottom: 42px;
  }
</style>
