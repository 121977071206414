<template>
    <div class="users-customer-table">

      <!-- Invite Table -->
      <el-table
        v-if="isInvite"
        empty-text="Keine Nutzer"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          class-name="id-column"
          prop="email"
          label="E-Mail">
        </el-table-column>
        <el-table-column
          prop="token"
          label="Token">
        </el-table-column>
        <el-table-column
          prop="accountTypeText"
          label="Account-Typ">
        </el-table-column>
        <el-table-column
          label="Aktionen">
          <template slot-scope="scope">
            <el-button slot="reference" @click="openInviteLink(scope.row)" type="text" size="small">Anmeldung</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- User Table -->
      <el-table
        v-else
        empty-text="Keine Nutzer"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          class-name="id-column"
          prop="id"
          label="ID">
        </el-table-column>
        <el-table-column
          v-if="isGarage"
          prop="garageName"
          label="Werkstatt-Name">
        </el-table-column>
        <el-table-column
          prop="name"
          label="Name">
        </el-table-column>
        <el-table-column
          prop="email"
          label="E-Mail">
        </el-table-column>
        <el-table-column
          prop="lastSignIn"
          label="Letzter Login">
        </el-table-column>
        <el-table-column
          label="Aktionen">
          <template slot-scope="scope">
            <!-- <el-button slot="reference" @click="sendResetPasswordMail(scope.row)" type="text" size="small">Zurücksetzen</el-button>
            &nbsp; -->
            <el-popconfirm
              title="Bist du dir sicher?"
              confirmButtonText='Ja'
              cancelButtonText='Nein'
              @onConfirm="changeUserEnabled(scope.row)"
            >
              <el-button :loading="lockLoading[scope.row.data._id]" slot="reference" type="danger" size="small">{{ scope.row.disabled ? 'Entsperren' : 'Sperren' }}</el-button>
            </el-popconfirm>
            
          </template>
        </el-table-column>
      </el-table>

      <div class="load-more">
        <el-button @click="$emit('loadmore')" :loading="loading">Mehr laden</el-button>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';
import { ENABLE_USER, DISABLE_USER, /*, SEND_USER_PASSWORD_RESET_MAIL */} from '@/vuex/modules/users/actions';
import constants from "@/constants";

export default {
  name: 'UserTable',
  props: ['items', 'loading', 'type'],
  data() {
    return {
      lockLoading: {}
    }
  },
  computed: {
    ...mapState({
      env: state => state.settings.env,
    }),
    isInvite() {
      return this.type === 'invited'
    },
    isGarage() {
      return this.type === constants.accountType.garage;
    },
    tableData() {
      return this.items.map(item => {
        
        if (this.isInvite) {
          return {
            ...item,
            accountTypeText: item.accountType === constants.accountType.garage ? "Werkstatt" : "Kunde"
          }
        }

        const lastSignIn = item._firebase.metadata.lastSignInTime;
        return {
          id: `...${item._id.substr(-6)}`,
          name: `${item.firstname} ${item.lastname}`,
          email: item._firebase.email,
          disabled: item._firebase.disabled,
          lastSignIn: lastSignIn ? moment(lastSignIn).calendar() : "-",
          data: item,
          ... this.isGarage ? ({
            garageName: item.garage.name
          }) : {}
        };
      });
    }
  },
  methods: {
    sendResetPasswordMail(/*user*/) {
      // this.$store.dispatch(SEND_USER_PASSWORD_RESET_MAIL, user.data._id);
    },
    openInviteLink(user) {
      const link = this.env === 'production' 
        ? `https://app.bynand.de/beta-invite?email=${user.email}&code=${user.token}`
        : `https://repboerse-staging.web.app/beta-invite?email=${user.email}&code=${user.token}`;
      
      window.open(link, '_blank');
    },
    async changeUserEnabled(user) {
      const userId = user.data._id;
      this.lockLoading = {
        ...this.lockLoading,
        [userId]: true
      };
      if (user.disabled) {
        await this.$store.dispatch(ENABLE_USER, userId);
      } else {
        await this.$store.dispatch(DISABLE_USER, userId);
      }
      this.lockLoading = {
        ...this.lockLoading,
        [userId]: false
      };
    }
  }
}
</script>

<style lang="scss"> 
.users-customer-table {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 18px;

  td.id-column .cell {
    white-space: nowrap;
    word-break: normal;
  }

  .load-more {
    margin-top: 40px;
    text-align: center;
  }
}
</style>
